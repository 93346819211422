import {
  createContext,
  DetailedHTMLProps,
  HTMLAttributes,
  useMemo,
  useState,
} from 'react';
import { ContextType } from 'src/types/context.type';
import { UserType } from './user/types';

export const AuthContext = createContext<ContextType>({
  currentUser: null,
  setCurrentUser: () => {},
});

const CURRENT_USER_STORAGE_KEY = 'currentUser';

const currentUserFromStorage = sessionStorage.getItem(
  CURRENT_USER_STORAGE_KEY,
);

export const Context = ({
  children,
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [currentUser, setCurrentUser] = useState<UserType | null>(
    currentUserFromStorage ? JSON.parse(currentUserFromStorage) : null,
  );

  const updateCurrentUserData = (user: UserType | null) => {
    if (!user) {
      sessionStorage.removeItem(CURRENT_USER_STORAGE_KEY);
    } else {
      sessionStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(user));
    }

    setCurrentUser(user);
  };

  const contextValue = useMemo(
    () => ({ currentUser, setCurrentUser: updateCurrentUserData }),
    [currentUser],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
