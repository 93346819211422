import { FilterValue, SortOrder } from 'antd/es/table/interface';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SortData } from 'src/components/table';
import { QueryParamsKeysEnum } from 'src/types/query-params-keys.enum';

export const useSearchParamsHelper = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = useMemo(
    () => searchParams.get(QueryParamsKeysEnum.SEARCH_TEXT) || '',
    [searchParams],
  );
  const currentPage = useMemo(
    () => Number(searchParams.get(QueryParamsKeysEnum.PAGE)) || 1,
    [searchParams],
  );
  const paginationLimit = useMemo(
    () =>
      searchParams.get(QueryParamsKeysEnum.PAGINATION_LIMIT)
        ? Number(searchParams.get(QueryParamsKeysEnum.PAGINATION_LIMIT))
        : null,
    [searchParams],
  );

  const sortField: string | null = useMemo(
    () => searchParams.get(QueryParamsKeysEnum.SORTING_FIELD) || null,
    [searchParams],
  );
  const sortOrder: SortOrder = useMemo(
    () =>
      (searchParams.get(QueryParamsKeysEnum.SORTING_ORDER) as SortOrder) ||
      null,
    [searchParams],
  );
  const currentTab = useMemo(
    () => searchParams.get(QueryParamsKeysEnum.CURRENT_TAB) || undefined,
    [searchParams],
  );

  const setPageParams = (newPage: number | string) => {
    setSearchParams((params) => {
      const page = params.get(QueryParamsKeysEnum.PAGE);

      if (page !== newPage.toString()) {
        params.set(QueryParamsKeysEnum.PAGE, newPage.toString());
      }
      return params;
    });
  };

  const setPaginationLimitParams = (limit: number) => {
    setSearchParams((params) => {
      params.set(QueryParamsKeysEnum.PAGINATION_LIMIT, limit.toString());
      params.set(QueryParamsKeysEnum.PAGE, '1');

      return params;
    });
  };

  const handleSetSearchParams = (search: string) => {
    setSearchParams((params) => {
      params.set(QueryParamsKeysEnum.PAGE, '1');
      params.set(QueryParamsKeysEnum.SEARCH_TEXT, search);
      return params;
    });
  };

  const setSortParams = (sortObj?: SortData) => {
    setSearchParams((params) => {
      if (sortObj?.field && sortObj?.order) {
        params.set(
          QueryParamsKeysEnum.SORTING_FIELD,
          sortObj.field?.toString(),
        );
        params.set(QueryParamsKeysEnum.SORTING_ORDER, sortObj.order);
      } else {
        params.delete(QueryParamsKeysEnum.SORTING_FIELD);
        params.delete(QueryParamsKeysEnum.SORTING_ORDER);
      }

      return params;
    });
  };

  const setSellsSearchParams = (key: string, value: string) => {
    setSearchParams((prev) => {
      console.log('setSellsSearchParams');
      const prevSellsSearch = prev.get(QueryParamsKeysEnum.SELLS_SEARCH);

      if (prevSellsSearch) {
        const parsed = JSON.parse(prevSellsSearch);

        if (!value) {
          delete parsed[key];
        } else {
          parsed[key] = value;
        }

        if (Object.keys(parsed).length) {
          prev.set(QueryParamsKeysEnum.SELLS_SEARCH, JSON.stringify(parsed));
        } else {
          prev.delete(QueryParamsKeysEnum.SELLS_SEARCH);
        }
      } else {
        if (value) {
          prev.set(
            QueryParamsKeysEnum.SELLS_SEARCH,
            JSON.stringify({ [key]: value }),
          );
        } else {
          prev.delete(QueryParamsKeysEnum.SELLS_SEARCH);
        }
      }

      prev.set(QueryParamsKeysEnum.PAGE, '1');

      return prev;
    });
  };

  const sellsSearch = useMemo(() => {
    const searchString = searchParams.get(QueryParamsKeysEnum.SELLS_SEARCH);

    return searchString ? JSON.parse(searchString) : {};
  }, [searchParams]);

  const setTabChangeParams = (tab: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams();

      prev.forEach((value, key) => {
        if (QueryParamsKeysEnum.PAGINATION_LIMIT === key) {
          newParams.set(key, value);
        }

        if (QueryParamsKeysEnum.PAGE === key) {
          newParams.set(key, '1');
        }
      });

      newParams.set(QueryParamsKeysEnum.CURRENT_TAB, tab);

      return newParams;
    });
  };

  const setFilterParams = (
    filtersData: Record<string, FilterValue | null>,
  ) => {
    setSearchParams((prev) => {
      Object.entries(filtersData).forEach(([filterKey, filterValues]) => {
        if (filterValues && filterValues.length > 0) {
          prev.set(
            filterKey,
            Array.isArray(filterValues)
              ? filterValues.join(',')
              : filterValues,
          );
        } else {
          prev.delete(filterKey);
        }
      });

      prev.set(QueryParamsKeysEnum.PAGE, '1');

      return prev;
    });
  };

  const filters = useMemo((): Record<string, FilterValue | null> => {
    const data: Record<string, FilterValue | null> = {};

    searchParams.forEach((value, key) => {
      if (
        ![
          QueryParamsKeysEnum.PAGE,
          QueryParamsKeysEnum.SEARCH_TEXT,
          QueryParamsKeysEnum.SORTING_FIELD,
          QueryParamsKeysEnum.SORTING_ORDER,
          QueryParamsKeysEnum.CURRENT_TAB,
          QueryParamsKeysEnum.PAGINATION_LIMIT,
          QueryParamsKeysEnum.SELLS_SEARCH,
        ].includes(key as QueryParamsKeysEnum)
      ) {
        try {
          const parsedValue = JSON.parse(value);
          data[key] = Array.isArray(parsedValue) ? parsedValue : [parsedValue];
        } catch {
          data[key] = value.split(',');
        }
      }
    });

    return data;
  }, [searchParams]);

  const clearAllFilters = () => {
    const newParams = new URLSearchParams();

    searchParams.forEach((value, key) => {
      if (
        [
          QueryParamsKeysEnum.PAGE,
          QueryParamsKeysEnum.CURRENT_TAB,
          QueryParamsKeysEnum.PAGINATION_LIMIT,
        ].includes(key as QueryParamsKeysEnum)
      ) {
        newParams.set(key, value);
      }
    });

    setSearchParams(newParams);
  };

  const canClear = useMemo(() => {
    return (
      !!searchText ||
      !!Object.keys(filters).length ||
      !!Object.keys(sellsSearch).length
    );
  }, [filters, sellsSearch, searchText]);

  return {
    searchParams,
    setPageParams,
    setPaginationLimitParams,
    handleSetSearchParams,
    setSortParams,
    setTabChangeParams,
    setFilterParams,
    setSellsSearchParams,
    clearAllFilters,
    canClear,
    sellsSearch,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
    currentTab,
    filters,
  };
};
