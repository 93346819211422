import React from 'react';
import { ConfigProvider, Empty, GetProp, Table, TableColumnsType } from 'antd';
import {
  FilterValue,
  SortOrder,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { LoaderUi } from 'src/ui';

import styles from './style.module.css';

export interface CustomColumnType extends TableColumnsType<any> {
  render?: (_: any, row: any) => React.ReactNode;
}

export interface SortData {
  field: string | undefined | React.Key | readonly React.Key[];
  order: SortOrder | undefined;
}

interface DataTableProps {
  data: any[];
  columns: CustomColumnType;
  setSort?: (val: SortData | undefined) => void;
  totalCount?: any;
  setPage?: (val: number | string) => void;
  currentPage?: any;
  loading?: boolean;
  pageLimit?: number;
  defPageSize?: any;
  setPaginationLimit?: (val: number) => void;
  setFilters?: (filters: Record<string, FilterValue | null>) => void;
  filters?: Record<string, FilterValue | null>;
  isLazyLoading?: boolean;
  emptyText?: string;
  noScroll?: boolean;
}

const renderEmpty: GetProp<typeof ConfigProvider, 'renderEmpty'> = (
  componentName,
) => {
  if (componentName === 'Table.filter') {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No Filter(Custom)"
      />
    );
  }
};

const DataTable = ({
  columns = [],
  data = [],
  pageLimit,
  setSort,
  totalCount,
  setPage,
  currentPage,
  loading = false,
  defPageSize,
  setPaginationLimit,
  setFilters,
  emptyText,
  noScroll,
}: DataTableProps) => {
  const handleTableChange = (
    paginationData: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    { action }: { action: 'paginate' | 'filter' | 'sort' },
  ) => {
    if (action === 'paginate') {
      if (setPaginationLimit) {
        setPaginationLimit(paginationData.pageSize || defPageSize);
      }
      if (setPage) {
        setPage(paginationData.current || 1);
      }
    }

    if (action === 'filter' && tableFilters && setFilters) {
      setFilters(tableFilters);
      if (setPage) {
        setPage(1);
      }
    }

    if (action === 'sort' && setSort) {
      if (Array.isArray(sorter)) {
        const firstSorter = sorter[0];
        setSort({
          field: firstSorter.field,
          order: firstSorter.order,
        });
      } else {
        setSort({
          field: sorter.field,
          order: sorter.order,
        });
      }
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ConfigProvider renderEmpty={renderEmpty}>
        <Table
          loading={{
            spinning: loading,
            indicator: <LoaderUi />,
            wrapperClassName: noScroll ? styles.spinnerWrapper : undefined,
          }}
          columns={columns}
          dataSource={data}
          scroll={
            data.length && !noScroll
              ? { x: 'max-content', y: '100%' }
              : undefined
          }
          onChange={handleTableChange}
          pagination={
            totalCount
              ? {
                  position: ['bottomCenter'],
                  total: totalCount,
                  current: currentPage,
                  pageSize: pageLimit,
                  showSizeChanger: true,
                  pageSizeOptions: [defPageSize, '20', '50', '100'],
                }
              : false
          }
          locale={{
            emptyText: (
              <Empty description={emptyText || 'No data available'} />
            ),
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default DataTable;
