import { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RolesEnum } from 'src/types';
import { PageNotFound } from 'src/ui';
import { ChatModelsList } from './chatModel';
import { CompaniesList } from './company';
import { AuthContext } from './context.component';
import { btnLinks, LeftMenu } from './left-menu.component';
import { ProxyList } from './proxy';
import { TopMenu } from './topMenu';
import { EditMyProfile, Login, UsersList } from './user';
import { Dashboard } from './dashboard';

import { SnackbarProvider } from 'notistack';
import { createStyles } from 'antd-style';
import { ConfigProvider } from 'antd';

const useStyle = createStyles(({ prefixCls, css }) => ({
  customButton: css`
    &.${prefixCls}-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
}));

export const App = () => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <Login />;
  }

  const { styles } = useStyle();

  return (
    <SnackbarProvider maxSnack={5}>
      <ConfigProvider
        button={{
          className: styles.customButton,
        }}>
        <div>
          <TopMenu />
          <div className="w-full m-auto h-screen min-h-major-960 m-auto flex flex-row items-start">
            <BrowserRouter>
              <LeftMenu />
              <Routes>
                <Route
                  index
                  path={btnLinks.dashboard}
                  element={<Dashboard />}
                />
                <Route path={btnLinks.companies} element={<CompaniesList />} />
                <Route path={btnLinks.people} element={<UsersList />} />
                <Route
                  path={btnLinks.chatModels}
                  element={<ChatModelsList />}
                />
                {currentUser && currentUser.role === RolesEnum.SUPER_ADMIN && (
                  <Route path={btnLinks.proxies} element={<ProxyList />} />
                )}
                <Route path={btnLinks.myProfile} element={<EditMyProfile />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </BrowserRouter>
          </div>
        </div>
      </ConfigProvider>
    </SnackbarProvider>
  );
};
