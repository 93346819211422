import {
  createRef,
  DetailedHTMLProps,
  HTMLAttributes,
  RefObject,
  useContext,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { UserType } from './types';
import { handleClickOutside } from 'src/helpers';
import { StoreUser } from './store-user.component';
import { AuthContext } from '../context.component';
import { RolesEnum } from 'src/types';

export const EditUser = ({
  userData,
  className,
  updateUserInList,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  userData: Partial<UserType>;
  updateUserInList: (data: any) => void;
}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const formRef: RefObject<HTMLDivElement> = createRef();

  const displayForm = () => {
    if (!showEditForm) {
      setShowEditForm(true);
    }
  };

  const outsideClickAction = () => {
    if (showEditForm) {
      setShowEditForm(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutside(e, formRef, outsideClickAction),
    );

    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutside(e, formRef, outsideClickAction),
      );
    };
  });

  const onUserStored = (user: UserType) => {
    updateUserInList((prev: UserType[]) =>
      prev.map((stored) => {
        if (stored.id === user.id) {
          return { ...stored, ...user };
        }

        return stored;
      }),
    );

    setShowEditForm(false);
  };

  return (
    <div
      className={cn(
        'relative',
        className,
        'hover:cursor-pointer',
        'hover:underline',
      )}
      onClick={displayForm}
      {...props}>
      {userData.username || ''}
      {showEditForm &&
        userData.id &&
        currentUser &&
        currentUser.role !== RolesEnum.CHATTER && (
          <StoreUser
            userId={userData.id}
            edit
            previewOnly
            isPopupMode
            ref={formRef}
            defaultValues={{
              ...userData,
            }}
            onUserStored={onUserStored}
            onClose={() => setShowEditForm(false)}
          />
        )}
    </div>
  );
};
