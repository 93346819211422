import {
  createRef,
  DetailedHTMLProps,
  HTMLAttributes,
  RefObject,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { ChatModelType } from './types';
import { handleClickOutside } from 'src/helpers';
import { StoreChatModel } from './store-chat-model.component';

export const EditChatModel = ({
  modelData,
  className,
  maxTopPosition,
  publicKey,
  updateCreatorInList,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  modelData: Partial<ChatModelType>;
  maxTopPosition?: number;
  publicKey: string | null;
  updateCreatorInList: (data: any) => void;
}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const formRef: RefObject<HTMLDivElement> = createRef();

  const displayForm = () => {
    if (!showEditForm) {
      setShowEditForm(true);
    }
  };

  const outsideClickAction = () => {
    if (showEditForm) {
      setShowEditForm(false);
    }
  };

  const onCreatorStored = (creator: ChatModelType) => {
    updateCreatorInList((prev: ChatModelType[]) =>
      prev?.map((stored) => {
        if (stored.id === creator.id) {
          return { ...stored, ...creator };
        }

        return stored;
      }),
    );
    setShowEditForm(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutside(e, formRef, outsideClickAction),
    );

    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutside(e, formRef, outsideClickAction),
      );
    };
  });

  return (
    <>
      <div
        className={cn(className, 'hover:cursor-pointer', 'hover:underline')}
        onClick={displayForm}
        {...props}>
        {modelData.name || ''}
      </div>
      {showEditForm && modelData.id && (
        <StoreChatModel
          modelId={modelData.id}
          companyId={modelData.company?.id}
          companyEmail={modelData.company?.contactEmail}
          platformId={modelData.platform?.id}
          proxyId={modelData.proxy?.id}
          maxTopPosition={maxTopPosition}
          edit
          previewOnly
          ref={formRef}
          defaultValues={{
            ...modelData,
            // companyId : modelData.company?.id,
            // companyEmail : modelData.company?.contactEmail,
            // platformId : modelData.platform?.id,
            // proxyId : modelData.proxy?.id
          }}
          publicKey={publicKey}
          onModelStored={onCreatorStored}
          onClose={() => setShowEditForm(false)}
        />
      )}
    </>
  );
};
