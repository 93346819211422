import { SortOrder } from 'antd/es/table/interface';

const checkSortOrder = (
  key: string,
  { field, order }: { field: string | null; order?: SortOrder },
) => {
  return field === key ? order : undefined;
};

export { checkSortOrder };
