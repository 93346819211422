import { gql } from '@apollo/client';

export const proxyDataFragment = gql`
  fragment ProxyData on Proxy {
    id
    country
    host
    port
    user
    password
    status
    assignedModels {
      name
    }
    company {
      name
    }
  }
`;
