import {
  ApolloQueryResult,
  DocumentNode,
  useQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { useState, useMemo } from 'react';
import { PaginationType } from 'src/types';

interface UseQueryHookProps<TData, TList> {
  query: DocumentNode;
  variables?: Record<string, any>;
  dumpFunction: (data: TData) => {
    list: TList[];
    pageInfo: PaginationType | null;
  };
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useQueryHook = <TResponse extends Record<string, any>, TList>({
  query,
  variables = {},
  dumpFunction,
  fetchPolicy,
}: UseQueryHookProps<TResponse, TList>) => {
  const [data, setData] = useState<TList[]>([]);
  const [pageData, setPageData] = useState<PaginationType | null>(null);

  const memoizedVariables = useMemo(() => variables, [variables]);

  const { loading, error, refetch } = useQuery<TResponse>(query, {
    fetchPolicy: fetchPolicy ?? 'no-cache',
    variables: memoizedVariables,
    onCompleted: (response) => {
      const { list, pageInfo } = dumpFunction(response);
      setData(list);
      setPageData(pageInfo);
    },
  });

  const refetchList = async () => {
    const response: ApolloQueryResult<TResponse> = await refetch();
    if (response.data) {
      const { list, pageInfo } = dumpFunction(response.data);
      setData(list);
      setPageData(pageInfo);
    }
  };

  return {
    loading,
    data,
    pageData,
    error,
    refetch: refetchList,
    updateData: setData,
  };
};

export default useQueryHook;
