import { geUserFirstApproveTime } from '../../helpers';
import { ListItemModel } from './typesListItem';
import { config } from '../../configs/config';
import { Tag } from 'antd';

export const RowApproved = ({ userData }: any) => {
  const approvedAt = geUserFirstApproveTime(
    // todo change approve time source;
    // contactsCollectingEndAt doesn't represent real approve time;
    userData.chatModels.map(
      (item: ListItemModel) => item.setting?.contactsCollectingEndAt,
    ),
  );

  return (
    <>
      {userData.chatModels.length ? (
        approvedAt ? (
          approvedAt.toLocaleString('ua', config.dateFormat) +
          ' ' +
          approvedAt.toLocaleTimeString()
        ) : (
          <Tag color="error">not parsed</Tag>
        )
      ) : (
        'n/a'
      )}
    </>
  );
};
