const prepareSearchBySells = (search: Record<string, string>) => {
  if (!search || !Object.keys(search).length) {
    return {};
  }

  return {
    searchBy: Object.entries(search).map(([key, value]) => ({
      field: key,
      value,
    })),
  };
};

export { prepareSearchBySells };
