import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react/context';
import { setContext } from '@apollo/client/link/context';
import { config } from 'src/configs/config';
import { DetailedHTMLProps, HTMLAttributes, useContext } from 'react';
import { AuthContext } from './context.component';
import { relayStylePagination } from '@apollo/client/utilities';

const graphqlLink = createHttpLink({
  uri: config.graphQlUrl,
});
const cache = new InMemoryCache({
  typePolicies: {
    AgencyGroup: {
      fields: {
        getCompanies: relayStylePagination(),
      },
    },
    ChatModelGroup: {
      fields: {
        getChatModels: relayStylePagination(),
        getAssignedUsersInWork: relayStylePagination(),
      },
    },
    UserGroup: {
      fields: {
        getUsers: relayStylePagination(['status']),
      },
    },
    Dictionary: {
      fields: {
        getProxies: relayStylePagination(['status']),
        getPlatforms: relayStylePagination(['platform']),
      },
    },
  },
});
const apolloClient = new ApolloClient({
  cache,
});

export const ApolloProviderContext = ({
  children,
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { currentUser } = useContext(AuthContext);
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'fcrm-sign':
          'c2xrZDIzNHNiZGZrc3piYzIzYXNkYmthYjIzdXNkdmJrc2RoMzk4YWhzdg==',
        authorization: currentUser ? `Bearer ${currentUser.jwt}` : '',
      },
    };
  });

  if (!currentUser) {
    apolloClient.clearStore().catch((err) => console.error(err));
  }

  apolloClient.setLink(authLink.concat(graphqlLink));

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
