import { ModelStatusEnum } from 'src/types';

const CREATOR_STATUSES = [
  {
    text: 'Active',
    value: ModelStatusEnum.ACTIVE,
  },
  {
    text: 'Confirmed',
    value: ModelStatusEnum.CONFIRMED,
  },
  {
    text: 'Failed credential',
    value: ModelStatusEnum.FAILED_CREDENTIALS,
  },
  {
    text: 'Outdated',
    value: ModelStatusEnum.OUTDATE,
  },
  {
    text: 'Inactive',
    value: ModelStatusEnum.INACTIVE,
  },
  {
    text: 'Unknown',
    value: ModelStatusEnum.UNKNOWN,
  },
];

export { CREATOR_STATUSES };
